import React, { useEffect, useState, useCallback } from 'react';

import {
  Button,
  Box,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router';
import Loader from '../../components/Loader';
import Amvoxlogopng from '../../assets/Amvoxlogopng.png';
import { useToast } from '../../hooks/components/toast.hook';
import { ModalIncluirEmprestimo } from './ModalIncluirEmprestimo';
import LayoutNovo from '../../components/LayoutNovo';
import TableControleEmprestimo from './TableControleEmprestimo';
import { bancos } from './EmprestimosMock';
import { BuscarControleEmprestimo } from '../../services/controleEmprestimo.service';
import { formatDatetoHtmlDay } from '../../utils/formatDate';

export default function ControleEmprestimo() {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const handleLogoClick = () => navigate('/principal');
  const [filtro, setFiltro] = useState({
    dataInicio: 'dd/mm/aaaa',
    dataFinal: formatDatetoHtmlDay(),
    banco: '',
  });
  const [loading, setLoading] = useState(false);
  const [showSuccessMessageEmprestimo, setShowSuccessMessageEmprestimo] =
    useState(false);
  const [data, setData] = useState([]);

  const handleLimparFiltro = () => {
    setFiltro({
      dataInicio: 'dd/mm/aaaa',
      dataFinal: formatDatetoHtmlDay(),
      banco: '',
    });
  };

  useEffect(() => {
    if (showSuccessMessageEmprestimo) {
      const timer = setTimeout(() => showSuccessMessageEmprestimo(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessageEmprestimo]);

  const handleGetEmprestimos = useCallback(async () => {
    setLoading(true);

    const filtros = {
      dataInicio: filtro.dataInicio,
      dataFinal: filtro.dataFinal,
      banco: filtro.banco,
    };

    await BuscarControleEmprestimo(filtros)
      .then((res) => {
        setData(res);
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Empréstimos buscados com sucesso',
        });
        setLoading(false);
      })
      .catch((err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao buscar empréstimos',
          description: 'Emprestimos não encontrados',
        });
        setLoading(false);
      });
  }, [filtro]);

  useEffect(() => {
    handleGetEmprestimos();
  }, [filtro]);

  return (
    <LayoutNovo>
      <Box
        sx={{
          width: '95%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={(theme) => ({
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 2,
              [theme.breakpoints.down('1025')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: 2,
              },
              [theme.breakpoints.down('590')]: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            })}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
                <ChevronLeftIcon />
              </IconButton>
              <img
                src={Amvoxlogopng}
                style={{ width: '150px', height: '100px', cursor: 'pointer' }}
              />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({
                  fontWeight: '500',
                  color: '#000',
                  fontSize: '24px',
                  [theme.breakpoints.down('590')]: {
                    display: 'flex',
                    fontSize: '18px',
                    textAlign: 'center',
                    flexWrap: 'nowrap',
                  },
                })}
              >
                Controle de Empréstimos
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {showSuccessMessageEmprestimo && (
              <Snackbar
                open={showSuccessMessageEmprestimo}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setShowSuccessMessageEmprestimo(false)}
                autoHideDuration={5000}
              >
                <Alert
                  iconMapping={{
                    success: <CheckCircleOutlineIcon fontSize="inherit" />,
                  }}
                >
                  Emprestimo incluído com sucesso.
                </Alert>
              </Snackbar>
            )}
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down('900')]: {
              flexWrap: 'wrap',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: 4,
              width: '100%',
              marginLeft: '24px',
              [theme.breakpoints.down('640')]: {
                flexWrap: 'wrap',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                width: '200px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                [theme.breakpoints.down('415')]: {
                  flexWrap: 'wrap',
                },
              })}
            >
              <Typography sx={{ fontSize: '12px' }}>
                Filtro por banco:
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="select-banco-label" shrink>
                  Banco
                </InputLabel>
                <Select
                  label="Banco"
                  labelId="select-banco-label"
                  size="medium"
                  value={filtro.banco}
                  onChange={(e) =>
                    setFiltro({
                      ...filtro,
                      banco: e.target.value,
                    })
                  }
                  displayEmpty
                  sx={{
                    width: '220px',
                    height: '48px',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    '& .MuiInputBase-root': {
                      height: '48px',
                      borderRadius: '8px',
                      '&:focus-within': {
                        border: '1px solid lightgray',
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Selecione o banco
                  </MenuItem>
                  {bancos.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ fontSize: '12px' }}>
                Selecione o período de vencimento:
              </Typography>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  gap: 2,
                  flexDirection: 'row',
                  [theme.breakpoints.down('415')]: {
                    flexWrap: 'wrap',
                  },
                })}
              >
                <TextField
                  label="Data Inicial"
                  type="date"
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                    display: 'flex',
                    width: '168px',
                    '& .MuiInputBase-root': {
                      height: '48px',
                      borderRadius: '8px',
                      '&:focus-within': {
                        border: '1px solid lightgray',
                      },
                    },
                  }}
                  value={filtro.dataInicio}
                  onChange={(e) =>
                    setFiltro({
                      ...filtro,
                      dataInicio: e.target.value,
                    })
                  }
                  inputProps={{
                    style: { height: '48px', width: '100%' },
                  }}
                />
                <TextField
                  label="Data Final"
                  type="date"
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                    display: 'flex',
                    width: '168px',
                    '& .MuiInputBase-root': {
                      height: '48px',
                      borderRadius: '8px',
                      '&:focus-within': {
                        border: '1px solid lightgray',
                      },
                    },
                  }}
                  value={filtro.dataFinal}
                  onChange={(e) =>
                    setFiltro({
                      ...filtro,
                      dataFinal: e.target.value,
                    })
                  }
                  inputProps={{
                    style: { height: '48px', width: '100%' },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 2,
              [theme.breakpoints.down('1070')]: {
                flexDirection: 'column',
                alignItems: 'flex-end',
              },
              [theme.breakpoints.down('900')]: {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                marginLeft: '24px',
              },
              [theme.breakpoints.down('415')]: {
                flexWrap: 'wrap',
              },
            })}
          >
            <Button
              onClick={handleLimparFiltro}
              variant="outlined"
              color="inherit"
              size="large"
            >
              Limpar filtro
            </Button>

            <ModalIncluirEmprestimo
              showSuccessMessageEmprestimo={showSuccessMessageEmprestimo}
            />
          </Box>
        </Box>
        <Box sx={{ width: '100%', marginTop: '24px' }}>
          {loading ? <Loader /> : <TableControleEmprestimo data={data} />}
        </Box>
      </Box>
    </LayoutNovo>
  );
}
