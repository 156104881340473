import React, { useContext, lazy, Suspense, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import {
  Relatorios,
  RelatoriosUm,
  RelatoriosDois,
  RelatoriosTres,
} from '../pages/Relatorios';
import { isAuthenticated } from '../services/auth';
import { VerificaNotaPortariaProvider } from '../hooks/notas-fiscais/nota-fiscal-verifica-portaria.hook';
import { NotasEmitidasProvider } from '../hooks/notas-fiscais/notas-emitidas.hook';
import { EntregasProvider } from '../hooks/entrega/entregas.hook';
import { Usuario } from '../hooks/usuarios/usuario.hook';
import Loading from '../components/Loading';
import Grafico from '../components/Graficos';
import NotaFiscalTemplate from '../components/TemplatesPdf/NotaFiscal';
import ControleRPCTemplate from '../components/TemplatesPdf/ControleRecebimentoPermanenciaContainer';
import UsuarioBloqueado from '../pages/UsuarioBloqueiado';
import useUsuarioLocal from '../hooks/usuarioLocal.hook';
import Manutencao from '../pages/Manutenção';
import GerenciamentoTi from '../pages/GerenciamentoTi';
import DashaboardTi from '../pages/GerenciamentoTi/Dashboard';
import GeradorQrCode from '../pages/GeradorQrCode';
import EntregasTeste from '../pages/Entregas/novaEntrega';
import { ContratosProvider } from '../hooks/contrato';
import LeitorQRCode from '../pages/LeitorQrCode';
import { Inteligencia } from '../pages/Inteligencia';
import { Catalogo } from '../pages/Catalogo';
import { PainelAeroporto } from '../pages/PainelAeroporto';
import ControleCubagemTemplate from '../components/TemplatesPdf/Cubagem/ControleCubagemTemplate';
import TelaChamados from '../pages/TelaChamadosGeral';
import ChamadosPorSetor from '../pages/TelaChamadosGeral/ChamadosSetor';
import { PlanejamentoProducao } from '../pages/Producoes/PlanejamentoProducao';
import TabelaMestra from '../pages/TabelaMestra';
import XChamados from '../pages/Chamados/Xindex';
import XContato from '../pages/Contato/Xindex';
import ModalCubagem from '../pages/Pedidos/components/modalCubagem';
import ControleEstoqueTi from '../pages/ControleEstoqueTi';
import HistoricoMovimentacaoTi from '../pages/ControleMovimentacaoTI';
import ApontamentosWeb from '../pages/ApontamentosWeb';
import RelatorioApontamentos from '../pages/RelatorioApontamentos';
import ApontamentoChamados from '../pages/ApontamentoChamados';
import ProdutoForaDeLinha from '../pages/ProdutoForaDeLinha';
import LogsMonitoramento from '../pages/LogsMonitoramento';
import UserMonitoramento from '../pages/LogsMonitoramento/usuarioMonitoramento';
import EstoqueMei from '../pages/EstoqueMei';
import MeiPdf from '../pages/EstoqueMei/components/PdfMei';
import KPIInventario from '../pages/KPIinventario';
import DashboardNotasFiscaisEmitidas from '../pages/NotasFiscaisEmitidas/dashboard';
import { buscarParametrizacaoPage } from '../services/parametrizacaocard.service';
import ApontamentoSanduicheira from '../pages/ApontamentoSanduicheira';
import DashboardChamados from '../pages/DashboardChamados';
import { DashboardQualidade } from '../pages/DashboardQualidade';
import ControleEmprestimo from '../pages/ControleEmprestimo';
const Login = lazy(() => import('../pages/Login'));
const Admin = lazy(() => import('../pages/Admin'));
const Chamados = lazy(() => import('../pages/Chamados'));
const ChecagemNotaEstoque = lazy(() => import('../pages/ChecagemNotaEstoque'));
const TitulosPagar = lazy(() => import('../pages/TitulosPagar'));
const TitulosReceber = lazy(() => import('../pages/TitulosReceber'));
const Principal = lazy(() => import('../pages/Principal'));
const ListaDeEquipamentos = lazy(() => import('../pages/ListaDeEquipamentos'));
const DashboardApresentation = lazy(() =>
  import('../pages/DashboardApresentation')
);
const NotasFiscais = lazy(() => import('../pages/NotasFiscais'));
const GerenciadorUser = lazy(() => import('../pages/GerenciadorUser'));
const Faturamento = lazy(() => import('../pages/Faturamento'));
const AdminUser = lazy(() => import('../pages/AdminUser'));
const OrdemServico = lazy(() => import('../pages/OrdemServico'));
const ContratoRMK = lazy(() => import('../pages/ContratosRMK'));
const Qrcode = lazy(() => import('../pages/Qrcode'));
const Thanks = lazy(() => import('../pages/Thanks'));
const Margens = lazy(() => import('../pages/Margens'));
const Estoque = lazy(() => import('../pages/Estoque'));
const VirtualSupply = lazy(() => import('../pages/VirtualSupply'));
const Contato = lazy(() => import('../pages/Contato'));
const Pedidos = lazy(() => import('../pages/Pedidos'));
const PageCubagem = lazy(() => import('../pages/PageCubagem'));
const Entrega = lazy(() => import('../pages/Entregas'));
const Produtos = lazy(() => import('../pages/Produtos'));
const Inspecao = lazy(() => import('../pages/Inspecao'));
const EntradaDeImportacao = lazy(() => import('../pages/EntradaDeImportacao'));
const Producao = lazy(() => import('../pages/Producoes'));
const NaoLogado = lazy(() => import('../pages/NaoLogado'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const DashboardNovo = lazy(() => import('../pages/DashboardNovo'));
const VisaoGeral = lazy(() => import('../pages/VisaoGeral'));
const NotasSaida = lazy(() => import('../pages/NotasSaída'));
const Importacao = lazy(() => import('../pages/Importacao'));
const Financeiro = lazy(() => import('../pages/Financeiro'));
const NotaFiscal = lazy(() => import('../pages/NotaFiscal'));
const Apresentacao = lazy(() => import('../pages/Apresentacao'));
const Promotoras = lazy(() => import('../pages/Promotoras'));
const EnviosBahia = lazy(() => import('../pages/EnviosBahia'));
const PosicaoDeClientes = lazy(() => import('../pages/ClientesComVendedores'));
const Apontamento = lazy(() => import('../pages/Apontamentos'));
const EficienciaWeb = lazy(() => import('../pages/EficienciaWeb'));
const Fornecedores = lazy(() => import('../pages/Fornecedores'));
const FaturamentoBrasil = lazy(() => import('../pages/FaturamentoBrasil'));
const ExpedicaoLog = lazy(() => import('../pages/ExpedicaoLog'));
const DashProducao = lazy(() => import('../pages/DashProducao'));
const DashComercial = lazy(() => import('../pages/DashComercial'));
const PromotorasCrud = lazy(() => import('../pages/PromotorasCrud'));
const ColetaEEntrega = lazy(() => import('../pages/ColetaEEntrega'));
const ProtocolodeNotas = lazy(() => import('../pages/ProtocolodeNotas'));
const CadastroProtocolodeNotas = lazy(() =>
  import('../pages/ProtocolodeNotas/cadastro')
);
const MovimentacaoCorrente = lazy(() =>
  import('../pages/MovimentacaoCorrente')
);
const MovimentacaoCorrenteMovimentacoes = lazy(() =>
  import('../pages/MovimentacaoCorrente/ConsultaMovimentacao')
);
const RecebimentoContainer = lazy(() =>
  import('../pages/RecebimentoContainer')
);
const Insumos = lazy(() => import('../pages/Insumos'));
const InsumosSaldo = lazy(() => import('../pages/InsumosSaldo'));
const CustodeRetorno = lazy(() => import('../pages/CustodeRetorno'));
const PaginaParaTestes = lazy(() => import('../pages/MargensTeste'));
const RedefinirSenha = lazy(() => import('../pages/RedefinirSenha'));
const NotasFiscaisEmitidas = lazy(() =>
  import('../pages/NotasFiscaisEmitidas')
);
const ColetaEEntregaColeta = lazy(() =>
  import('../pages/ColetaEEntrega/Coleta')
);
const ColetaEEntregaEntrega = lazy(() =>
  import('../pages/ColetaEEntrega/Entrega')
);
const RegistroDeFornecedores = lazy(() =>
  import('../pages/RegistroDeFornecedores')
);
const PrintPage = lazy(() => import('../pages/PrintPage'));
const GerenciamentoInfo = lazy(() => import('../pages/GerenciamentoInfo'));
const InventarioEquipamento = lazy(() =>
  import('../pages/InventarioEquipamento')
);
const HistoricoMovimentacaoEquipamento = lazy(() =>
  import('../pages/InventarioEquipamento/HistoricoMovimentacaoEquipamento')
);
const LogServicos = lazy(() => import('../pages/LogServicos'));
const AprovadorProtocoloDeNotasPagina = lazy(() =>
  import('../pages/AprovadorProtocoloDeNotas')
);
const EntradaProtocoloNotas = lazy(() =>
  import('../pages/EntradaProtocoloNotas')
);
const ExpedicaoLogistica = lazy(() => import('../pages/ExpedicaoLogistica'));
const AgendamentoCarga = lazy(() => import('../pages/AgendamentoCarga'));
const TaxaDeOcupacao = lazy(() => import('../pages/TaxaDeOcupacao'));
const IndicadoresNps = lazy(() => import('../pages/IndicadoresNps'));
const PatchNotes = lazy(() => import('../pages/PatchNotes'));

export default function Rotas() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route
            path="/visaogeral"
            exact
            element={
              <RequireAuth path="visaogeral" id={null}>
                <VisaoGeral />
              </RequireAuth>
            }
          />
          {/* Início Rotas Setor Comercial */}
          <Route
            path="/pedidos"
            exact
            element={
              <RequireAuth path="pedidos" setorAccess={[1, 2, 7]}>
                <Pedidos />
              </RequireAuth>
            }
          />
          <Route
            path="/PageCubagem"
            exact
            element={
              <RequireAuth path="PageCubagem" setorAccess={[1, 2, 7]}>
                <PageCubagem />
              </RequireAuth>
            }
          />
          <Route
            path="/notasfiscaisemitidas"
            exact
            element={
              <RequireAuth
                path="notasfiscaisemitidas"
                setorAccess={[1, 3, 2, 7, 9]}
              >
                <NotasEmitidasProvider>
                  <NotasFiscaisEmitidas />
                </NotasEmitidasProvider>
              </RequireAuth>
            }
          />
          {/* Fim Rotas Setor Comercial */}
          <Route path="/relatorios" exact element={<Relatorios />} />
          <Route
            path="/relatorios/relatorios1"
            exact
            element={
              <RequireAuth path="relatorios/relatorios1" id={null}>
                <RelatoriosUm />
              </RequireAuth>
            }
          />
          <Route
            path="/relatorios/relatorios2"
            exact
            element={
              <RequireAuth path="relatorios/relatorios2" id={null}>
                <RelatoriosDois />
              </RequireAuth>
            }
          />
          <Route
            path="/relatorios/relatorios3"
            exact
            element={
              <RequireAuth path="relatorios/relatorios3" id={null}>
                <RelatoriosTres />
              </RequireAuth>
            }
          />
          <Route
            path="/printpdf/:documento"
            exact
            element={
              <RequireAuth path="printpdf/:documento" id={null}>
                <PrintPage>
                  <NotaFiscalTemplate />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/printpdfmei"
            exact
            element={
              <RequireAuth path="printpdfmei" id={null}>
                <PrintPage>
                  <MeiPdf />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/printrpc/:documento"
            exact
            element={
              <RequireAuth path="printrpc/:documento" id={null}>
                <PrintPage>
                  <ControleRPCTemplate />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/printcubagem/:documento"
            exact
            element={
              <RequireAuth path="printcubagem/:documento" id={null}>
                <PrintPage>
                  <ControleCubagemTemplate />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/ModalCubagem"
            exact
            element={
              <RequireAuth path="ModalCubagem" id={null}>
                <PrintPage>
                  <ModalCubagem />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/produtos"
            exact
            element={
              <RequireAuth
                path="produtos"
                id={3}
                nivelAccess={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
              >
                <Produtos />
              </RequireAuth>
            }
          />
          <Route
            path="/graficos"
            exact
            element={
              <RequireAuth path="graficos" id={null}>
                <Grafico />
              </RequireAuth>
            }
          />
          <Route
            path="/geradorqrcode"
            exact
            element={
              <RequireAuth path="geradorqrcode" id={null}>
                <GeradorQrCode />
              </RequireAuth>
            }
          />
          <Route
            path="/posicaodeclientes"
            exact
            element={
              <RequireAuth path="posicaodeclientes" id={37}>
                <PosicaoDeClientes />
              </RequireAuth>
            }
          />
          <Route
            path="/qrcode"
            exact
            element={
              <RequireAuth path="qrcode" id={null}>
                <Qrcode />
              </RequireAuth>
            }
          />
          <Route
            path="/apontamentos"
            exact
            element={
              <RequireAuth path="apontamentos" id={4}>
                <Apontamento />
              </RequireAuth>
            }
          />
          <Route
            path="/eficienciaWeb"
            exact
            element={
              <RequireAuth path="eficienciaWeb" id={4}>
                <EficienciaWeb />
              </RequireAuth>
            }
          />
          <Route
            path="/checagemnotaestoque"
            exact
            element={
              <RequireAuth path="checagemnotaestoque" id={null}>
                <ChecagemNotaEstoque />
              </RequireAuth>
            }
          />
          <Route
            path="/estoquemei"
            exact
            element={
              <RequireAuth path="estoquemei" id={null}>
                <EstoqueMei />
              </RequireAuth>
            }
          />
          <Route
            path="/inventariokpi"
            exact
            element={
              <RequireAuth path="inventariokpi" id={null}>
                <KPIInventario />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/ordemservicos"
            exact
            element={
              <RequireAuth path="inteligencia/ordemservicos" id={5}>
                <OrdemServico />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/apontamentosWeb"
            exact
            element={
              <RequireAuth path="inteligencia/apontamentosWeb" id={5}>
                <ApontamentosWeb />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/relatorioApontamentos"
            exact
            element={
              <RequireAuth path="inteligencia/relatorioApontamentos" id={5}>
                <RelatorioApontamentos />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/controleEstoqueTi"
            exact
            element={
              <RequireAuth path="inteligencia/controleEstoqueTi" id={5}>
                <ControleEstoqueTi />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/monitoramento"
            exact
            element={
              <RequireAuth path="inteligencia/monitoramento" id={5}>
                <LogsMonitoramento />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/usermonitoramento"
            exact
            element={
              <RequireAuth path="inteligencia/usermonitoramento" id={5}>
                <UserMonitoramento />
              </RequireAuth>
            }
          />
          <Route
            path="/margens"
            exact
            element={
              <RequireAuth path="margens" id={6}>
                <Margens />
              </RequireAuth>
            }
          />
          <Route
            path="/entregas"
            exact
            element={
              <RequireAuth
                path="entregas"
                id={2}
                setorAccess={[1, 2, 9, 8, 15, 7, 11]}
              >
                <EntregasProvider>
                  <Entrega />
                </EntregasProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/paginatest"
            exact
            element={
              <RequireAuth
                path="paginatest"
                id={2}
                setorAccess={[1, 2, 8, 7, 9, 15]}
              >
                <EntregasProvider>
                  <EntregasTeste />
                </EntregasProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/estoque"
            exact
            element={
              <RequireAuth path="estoque" id={8}>
                <Estoque />
              </RequireAuth>
            }
          />
          <Route />
          <Route
            path="/producoes"
            exact
            element={
              <RequireAuth path="producoes" id={10}>
                <Producao />
              </RequireAuth>
            }
          />
          <Route
            path="/producoes"
            exact
            element={
              <RequireAuth path="producoes" id={10}>
                <Producao />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            exact
            element={
              <RequireAuth path="dashboard" id={1}>
                <DashboardNovo />
              </RequireAuth>
            }
          />
          <Route
            path="/promotoras"
            exact
            element={
              <RequireAuth path="promotoras" id={11}>
                <Promotoras />
              </RequireAuth>
            }
          />
          <Route
            path="/promotorascrud"
            exact
            element={
              <RequireAuth path="promotorascrud" id={12}>
                <PromotorasCrud />
              </RequireAuth>
            }
          />
          <Route
            path="/faturamento"
            exact
            element={
              <RequireAuth path="faturamento" id={13}>
                <Faturamento />
              </RequireAuth>
            }
          />
          <Route
            path="/expedicaolog"
            exact
            element={
              <RequireAuth path="expedicaolog" id={14}>
                <ExpedicaoLog />
              </RequireAuth>
            }
          />
          <Route
            path="/virtualsupply"
            exact
            element={
              <RequireAuth path="virtualsupply" id={15}>
                <VirtualSupply />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboardapresentation"
            exact
            element={
              <RequireAuth path="dashboardapresentation" id={16}>
                <DashboardApresentation />
              </RequireAuth>
            }
          />
          <Route
            path="/import_c7_entrada"
            exact
            element={
              <RequireAuth path="import_c7_entrada" id={17}>
                <EntradaDeImportacao />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/admin"
            exact
            element={
              <RequireAuth path="inteligencia/admin" id={18}>
                <Admin />
              </RequireAuth>
            }
          />
          <Route
            path="/notafiscal/:documento"
            exact
            element={
              <RequireAuth path="notafiscal/:documento" id={19}>
                <NotaFiscal />
              </RequireAuth>
            }
          />
          <Route
            path="/fiscal"
            exact
            element={
              <RequireAuth path="fiscal" id={19}>
                <NotasFiscais />
              </RequireAuth>
            }
          />
          <Route
            path="/dashproducao"
            exact
            element={
              <RequireAuth path="dashproducao" id={1}>
                <DashProducao />
              </RequireAuth>
            }
          />
          <Route
            path="/dashcomercial"
            exact
            element={
              <RequireAuth path="dashcomercial" id={21}>
                <DashComercial />
              </RequireAuth>
            }
          />
          <Route
            path="/inteligencia/listadeequipamentos"
            exact
            element={
              <RequireAuth path="inteligencia/listadeequipamentos" id={22}>
                <ListaDeEquipamentos />
              </RequireAuth>
            }
          />
          <Route
            path="/contato"
            exact
            element={
              <RequireAuth path="contato" id={23}>
                <Contato />
              </RequireAuth>
            }
          />
          <Route
            path="/telachamados"
            exact
            element={
              <RequireAuth path="telachamados" id={23}>
                <TelaChamados />
              </RequireAuth>
            }
          />
          <Route
            path="/inteligencia/dashboardchamados"
            exact
            element={
              <RequireAuth path="telachamados" id={23}>
                <DashboardChamados />
              </RequireAuth>
            }
          />
          <Route
            path="/chamadosporsetor"
            exact
            element={
              <RequireAuth path="chamadosporsetor" id={23}>
                <ChamadosPorSetor />
              </RequireAuth>
            }
          />
          <Route
            path="/importacao"
            element={
              <RequireAuth path="importacao" id={24}>
                <Importacao />
                import DashboardChamados from
                './../pages/DashboardChamados/index';
              </RequireAuth>
            }
          />
          <Route
            path="/faturamentobrasil"
            exact
            element={
              <RequireAuth path="faturamentobrasil" id={25}>
                <FaturamentoBrasil />
              </RequireAuth>
            }
          />
          <Route
            path="/financeiro"
            exact
            element={
              <RequireAuth
                path="financeiro"
                id={26}
                setorAccess={[2, 4, 7, 15]}
              >
                <Financeiro />
              </RequireAuth>
            }
          />
          <Route
            path="/inspecao"
            exact
            element={
              <RequireAuth path="inspecao" id={27}>
                <Inspecao />
              </RequireAuth>
            }
          />
          <Route
            path="/enviosbahia"
            exact
            element={
              <RequireAuth path="enviosbahia" id={null}>
                <EnviosBahia />
              </RequireAuth>
            }
          />
          <Route
            path="/fornecedores"
            exact
            element={
              <RequireAuth path="fornecedores" id={28}>
                <Fornecedores />
              </RequireAuth>
            }
          />
          <Route
            path="/registrofornecedoreschina"
            exact
            element={
              <RequireAuth path="registrofornecedoreschina" id={29}>
                <RegistroDeFornecedores />
              </RequireAuth>
            }
          />
          <Route
            path="/contratos"
            exact
            element={
              <RequireAuth path="contratos" id={30}>
                <ContratosProvider>
                  <ContratoRMK />
                </ContratosProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/protocolodenotas"
            exact
            element={
              <RequireAuth path="protocolodenotas" id={31}>
                <ProtocolodeNotas />
              </RequireAuth>
            }
          />
          <Route
            path="/cadastroprotocolodenotas"
            exact
            element={
              <RequireAuth path="cadastroprotocolodenotas" id={31}>
                <CadastroProtocolodeNotas />
              </RequireAuth>
            }
          />
          <Route
            path="/aprovadorprotocolodenotas"
            exact
            element={
              <RequireAuth path="aprovadorprotocolodenotas" id={31}>
                <AprovadorProtocoloDeNotasPagina />
              </RequireAuth>
            }
          />
          <Route
            path="/notassaida"
            exact
            element={
              <RequireAuth
                path="notassaida"
                id={32}
                setorAccess={[0, 2, 7, 13, 15]}
              >
                <VerificaNotaPortariaProvider>
                  <NotasSaida />
                </VerificaNotaPortariaProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/thanks"
            exact
            element={
              <RequireAuth path="thanks" id={null}>
                <Thanks />
              </RequireAuth>
            }
          />
          <Route
            path="/coletaeentrega"
            exact
            element={
              <RequireAuth path="coletaeentrega" id={34}>
                <ColetaEEntrega />
              </RequireAuth>
            }
          />

          <Route
            path="/coletaeentrega/coleta"
            exact
            element={
              <RequireAuth path="coletaeentrega/coleta" id={35}>
                <ColetaEEntregaColeta />
              </RequireAuth>
            }
          />
          <Route
            path="/coletaeentrega/entrega"
            exact
            element={
              <RequireAuth path="coletaeentrega/entrega" id={36}>
                <ColetaEEntregaEntrega />
              </RequireAuth>
            }
          />
          <Route
            path="/apresentacao"
            exact
            element={
              <RequireAuth path="apresentacao" id={51}>
                <Apresentacao />
              </RequireAuth>
            }
          />

          <Route
            path="/titulospagar"
            exact
            element={
              <RequireAuth path="titulospagar" id={38}>
                <TitulosPagar />
              </RequireAuth>
            }
          />
          <Route
            path="/titulosreceber"
            exact
            element={
              <RequireAuth path="titulosreceber" id={39}>
                <TitulosReceber />
              </RequireAuth>
            }
          />
          <Route
            path="/movimentacaocorrente"
            exact
            element={
              <RequireAuth path="movimentacaocorrente" id={40}>
                <MovimentacaoCorrente />
              </RequireAuth>
            }
          />
          <Route
            path="/movimentacaocorrente/movimentacoes"
            exact
            element={
              <RequireAuth path="movimentacaocorrente/movimentacoes" id={41}>
                <MovimentacaoCorrenteMovimentacoes />
              </RequireAuth>
            }
          />
          <Route
            path="/recebimentopermanenciacontainer"
            exact
            element={
              <RequireAuth path="recebimentopermanenciacontainer" id={42}>
                <RecebimentoContainer />
              </RequireAuth>
            }
          />
          <Route
            path="/insumosmov"
            exact
            element={
              <RequireAuth path="insumosmov" id={43}>
                <Insumos />
              </RequireAuth>
            }
          />
          <Route
            path="/inteligencia/movimentacaoTi"
            exact
            element={
              <RequireAuth path="inteligencia/movimentacaoTi" id={43}>
                <HistoricoMovimentacaoTi />
              </RequireAuth>
            }
          />
          <Route
            path="/insumossaldo"
            exact
            element={
              <RequireAuth path="insumossaldo" id={44}>
                <InsumosSaldo />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/dashboardqualidade"
            exact
            element={
              <RequireAuth path="inteligencia/dashboardqualidade" id={null}>
                <DashboardQualidade />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/gerenciamentoinfo"
            exact
            element={
              <RequireAuth path="inteligencia/gerenciamentoinfo" id={45}>
                <GerenciamentoInfo />
              </RequireAuth>
            }
          />
          <Route
            path="/custoderetorno"
            exact
            element={
              <RequireAuth path="custoderetorno" id={46}>
                <CustodeRetorno />
              </RequireAuth>
            }
          />
          <Route
            path="/redefinirsenha"
            exact
            element={
              <RequireAuth path="redefinirsenha" id={47}>
                <RedefinirSenha />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia"
            exact
            element={
              <RequireAuth path="inteligencia" id={null}>
                <Inteligencia />
              </RequireAuth>
            }
          />
          <Route
            path="patchNotes"
            exact
            element={
              <RequireAuth path="patchNotes" id={null}>
                <PatchNotes />
              </RequireAuth>
            }
          />
          <Route
            path="catalogo"
            exact
            element={
              <RequireAuth path="catalogo" id={null}>
                <Catalogo />
              </RequireAuth>
            }
          />
          <Route
            path="painelAeroporto"
            exact
            element={
              <RequireAuth path="painelAeroporto" id={null}>
                <PainelAeroporto />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/gerenciadoruser"
            exact
            element={
              <RequireAuth path="admin/gerenciadoruser" id={48}>
                <GerenciadorUser />
              </RequireAuth>
            }
          />
          <Route
            path="/adminuser"
            exact
            element={
              <RequireAuth path="adminuser" id={49}>
                <AdminUser />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/gerenciamentoti"
            exact
            element={
              <RequireAuth path="inteligencia/gerenciamentoti" id={50}>
                <GerenciamentoTi />
              </RequireAuth>
            }
          />
          <Route
            path="/gerenciamentoti/dashboard"
            exact
            element={
              <RequireAuth path="gerenciamentoti/dashboard" id={50}>
                <DashaboardTi />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/gerenciamentodechamado"
            exact
            element={
              <RequireAuth path="inteligencia/gerenciamentodechamado" id={51}>
                <Chamados />
              </RequireAuth>
            }
          />
          <Route
            path="/gerenciamentodechamado"
            exact
            element={
              <RequireAuth path="gerenciamentodechamado" id={51}>
                <Chamados />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/inventario"
            exact
            element={
              <RequireAuth path="inteligencia/inventario" id={60}>
                <InventarioEquipamento />
              </RequireAuth>
            }
          />
          <Route
            path="/inventario/historicoequipamento"
            exact
            element={
              <RequireAuth path="inventario/historicoequipamento" id={60}>
                <HistoricoMovimentacaoEquipamento />
              </RequireAuth>
            }
          />
          <Route
            path="controleemprestimo"
            exact
            element={
              <RequireAuth
                path="controleemprestimo"
                id={61}
                setorAccess={[2, 4, 7, 15]}
              >
                <ControleEmprestimo />
              </RequireAuth>
            }
          />
          <Route
            path="/expedicaologistica"
            exact
            element={
              <RequireAuth path="expedicaologistica" id={null}>
                <ExpedicaoLogistica />
              </RequireAuth>
            }
          />
          <Route
            path="/agendamentocarga"
            exact
            element={
              <RequireAuth path="agendamentocarga" id={null}>
                <AgendamentoCarga />
              </RequireAuth>
            }
          />
          <Route
            path="/taxadeocupacao"
            exact
            element={
              <RequireAuth path="taxadeocupacao" id={null}>
                <TaxaDeOcupacao />
              </RequireAuth>
            }
          />
          <Route
            path="/kpisposvenda"
            exact
            element={
              <RequireAuth path="kpisposvenda" id={null}>
                <IndicadoresNps />
              </RequireAuth>
            }
          />
          <Route
            path="/planejamentoproducao"
            exact
            element={
              <RequireAuth path="planejamentoproducao" id={null}>
                <PlanejamentoProducao />
              </RequireAuth>
            }
          />
          <Route
            path="/tabelamestra"
            exact
            element={
              <RequireAuth path="tabelamestra" id={null}>
                <TabelaMestra />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/logservicos"
            exact
            element={
              <RequireAuth path="inteligencia/logservicos" id={null}>
                <LogServicos />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/apontamentochamados"
            exact
            element={
              <RequireAuth path="inteligencia/apontamentochamados" id={null}>
                <ApontamentoChamados />
              </RequireAuth>
            }
          />
          <Route
            path="/principal"
            exact
            element={
              <RequireAuth path="principal" id={null}>
                <Principal />
              </RequireAuth>
            }
          />
          <Route
            path="/manutencao"
            exact
            element={
              <RequireAuth path="manutencao" id={null}>
                <Manutencao />
              </RequireAuth>
            }
          />
          <Route
            path="/registroqrcode"
            exact
            element={
              <RequireAuth path="registroqrcode" id={null}>
                <LeitorQRCode />
              </RequireAuth>
            }
          />
          <Route
            path="/entradaprotocolonotas"
            exact
            element={
              <RequireAuth path="entradaprotocolonotas" id={null}>
                <EntradaProtocoloNotas />
              </RequireAuth>
            }
          />
          <Route
            path="/xchamados"
            exact
            element={
              <RequireAuth path="xchamados" id={null}>
                <XChamados />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/xchamados"
            exact
            element={
              <RequireAuth path="inteligencia/xchamados" id={null}>
                <XChamados />
              </RequireAuth>
            }
          />
          <Route
            path="/xcontato"
            exact
            element={
              <RequireAuth path="xcontato" id={null}>
                <XContato />
              </RequireAuth>
            }
          />
          <Route
            path="/historicodoproduto"
            exact
            element={
              <RequireAuth path="historicodoproduto" id={null}>
                <ProdutoForaDeLinha />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboardnotasfiscais"
            exact
            element={
              <RequireAuth path="dashboardnotasfiscais" id={null}>
                <DashboardNotasFiscaisEmitidas />
              </RequireAuth>
            }
          />
          <Route
            path="/apontamentosanduicheira"
            exact
            element={
              <RequireAuth path="apontamentosanduicheira" id={null}>
                <ApontamentoSanduicheira />
              </RequireAuth>
            }
          />

          <Route path="/401" exact element={<NaoLogado />} />
          <Route path="/noaccess" exact element={<UsuarioBloqueado />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

function RequireAuth({
  children,
  path,
  setorAccess = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28,
  ],
}) {
  const { loading } = useContext(Usuario);
  const [statusPage, setStatusPage] = useState([]);
  const { setor, nivel } = useUsuarioLocal();
  const navigateHook = useNavigate();

  useEffect(() => {
    buscarParametrizacaoPage().then((res) => {
      setStatusPage(res);
    });
  }, [path]);

  useEffect(() => {
    let pathUper = path ? path.toUpperCase() : '';
    let resultado = pathUper
      ? statusPage.find((item) => item.descricao === pathUper)
      : '';

    if (resultado?.status === 0) {
      navigateHook('/manutencao');
    }
    if (statusPage === '') {
      return children;
    }
  }, [statusPage]);

  if (loading) {
    return <Loading />;
  }

  const setorPermitido = setorAccess.includes(setor);

  if (!setorPermitido) {
    return <Navigate to="/401" />;
  }

  if (!isAuthenticated()) {
    return <Navigate to="/401" />;
  }
  return children;
}
