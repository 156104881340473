import { useContext, useState, useEffect, createContext } from 'react';
import { apiBVSP, apiCotacao } from '../../services/apis';

const Cotacao = createContext({
  dollar: 0,
  euro: 0,
  bitcoin: 0,
  yuan: 0,
  bitcoinUSD: 0,
  bvsp: 0,
});

export const CotacaoProvider = (props) => {
  const [dollar, setDollar] = useState();
  const [euro, setEuro] = useState();
  const [bitcoin, setBitcoin] = useState();
  const [yuan, setYuan] = useState();
  const [selic, setSelic] = useState();
  const [bitcoinUSD, setBitcoinUSD] = useState();
  const [ipca, setIpca] = useState();
  const [bvsp, setBvsp] = useState();

  useEffect(() => {
    apiCotacao('/last/USD,EUR,BTC,CNY,BTC-USD').then((response) => {
      setDollar(response.data.USDBRL);
      setEuro(response.data.EURBRL);
      setBitcoin(response.data.BTCBRL);
      setYuan(response.data.CNYBRL);
      setBitcoinUSD(response.data.BTCUSD);
    });
    // apiIPCA().then((response) => {
    //   setIpca(response.data[0].valor);
    // });

    const timer = setInterval(() => {
      apiCotacao('/last/USD,EUR,BTC,CNY,BTC-USD').then((response) => {
        setDollar(response.data.USDBRL);
        setEuro(response.data.EURBRL);
        setBitcoin(response.data.BTCBRL);
        setYuan(response.data.CNYBRL);
        setBitcoinUSD(response.data.BTCUSD);
      });
      // apiIPCA().then((response) => {
      //   setIpca(response.data[0].valor);
      // });
    }, 300000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Cotacao.Provider
      value={{ dollar, euro, yuan, bitcoin, selic, ipca, bvsp, bitcoinUSD }}
    >
      {props.children}
    </Cotacao.Provider>
  );
};

export const useCotacao = (props) => {
  const context = useContext(Cotacao);
  if (!context) console.error('Erro na cotacao');
  return context;
};
