import * as React from 'react';
import { useState } from 'react';
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Typography,
  Modal,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  PostControleEmprestimo,
  BuscarVencimentos,
} from '../../../services/controleEmprestimo.service';

const style = {
  position: 'absolute',
  top: '60%',
  left: '80%',
  transform: 'translate(-50%, -50%)',
  width: '350px',
  height: '300px',
  bgcolor: 'background.paper',
  border: '1px solid #333333',
  borderRadius: '8px',
  boxShadow: 10,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
};

const status = [
  { label: 'Concluido', value: 'Concluido' },
  { label: 'Parcial', value: 'Parcial' },
  {
    label: 'Pendente',
    value: 'Pendente',
  },
];

const ModalEditarParcela = ({ isOpen, onClose, rowData }) => {
  const [formData, setFormData] = useState({
    vlrParcela: rowData.vlrParcela,
    status: rowData.status,
  });

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="20px"
          >
            <Typography id="modal-modal-title" sx={{ fontWeight: 'bold' }}>
              Editar Parcela N° {rowData.id}
            </Typography>
            <CloseIcon onClick={onClose} />
          </Stack>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              marginTop: '25px',
            }}
          >
            <TextField
              id="outlined-basic"
              label="Valor Pago"
              variant="outlined"
              size="small"
              sx={{ flex: '5 1 auto' }}
              value={
                formData.vlrParcela !== undefined
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(parseFloat(formData.vlrParcela || 0))
                  : ''
              }
              onChange={(e) => {
                let inputValue = e.target.value;

                const numericValue = inputValue.replace(/[^\d]/g, '');

                const parsedValue = numericValue
                  ? parseFloat(numericValue) / 100
                  : 0;

                handleInputChange('vlrParcela', parsedValue);
              }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="select-status-label" shrink>
                Status
              </InputLabel>
              <Select
                size="small"
                label="Status"
                labelId="select-status-label"
                value={formData.status}
                onChange={(e) => handleInputChange('status', e.target.value)}
                displayEmpty
              >
                {status.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              marginTop: '25px',
              justifyContent: 'flex-end',
            }}
          >
            <Button variant="contained" color="primary" size="medium">
              Incluir
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalEditarParcela;
