export const bancos = [
  { label: 'Banco do Brasil', value: 'Banco do Brasil' },
  { label: 'Bradesco', value: 'Bradesco' },
  { label: 'Itau', value: 'Itau' },
  { label: 'Santander', value: 'Santander' },
  { label: 'Caixa Econômica', value: 'Caixa Econômica' },
  { label: 'Unibanco', value: 'Unibanco' },
  { label: 'Nubank', value: 'Nubank' },
  { label: 'Banco Inter', value: 'Banco Inter' },
  { label: 'Banco Original', value: 'Banco Original' },
  { label: 'Sofisa', value: 'Sofisa' },
  { label: 'Safra', value: 'Safra' },
  { label: 'Daycoval', value: 'Daycoval' },
  { label: 'C6 Bank', value: 'C6 Bank' },
];

export const operacao = [
  { label: 'FGI', value: 'FGI' },
  { label: 'Crédito para Exportação', value: 'Crédito para Exportação' },
  { label: 'Crédito para Importação', value: 'Crédito para Importação' },
  { label: 'Crédito para Compra', value: 'Crédito para Compra' },
  { label: 'Crédito para Venda', value: 'Crédito para Venda' },
  { label: 'Capital de Giro', value: 'Capital de Giro' },
  { label: 'Finame/BNDES', value: 'Finame/BNDES' },
];

export const parcelas = [
  { parcela: '1', valorParcela: '10000000', dataVencimento: '2023-01-01' },
  { parcela: '2', valorParcela: '20000000', dataVencimento: '2023-02-01' },
  { parcela: '3', valorParcela: '30000000', dataVencimento: '2023-03-01' },
  { parcela: '4', valorParcela: '40000000', dataVencimento: '2023-04-01' },
];

export const emprestimos = [
  {
    id: 1,
    contratosAtivos: 'Santander',
    dtContratada: '2025-01-22T00:00:00',
    txCaptacao: 2.0,
    modelCaptacao: 'FGI',
    vlrContratado: 10.0,
    saldo_A_Pagar: -9.8,
    saldo_Devedor: 10.0,
    porcentGarantia: 2.0,
    dtUltimaParcela: '0001-01-01T00:00:00',
    total: -19.8,
    periodoCarreira: '20',
    detalhes: [
      {
        id: 1,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Concluido',
      },
      {
        id: 2,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Pendente',
      },
    ],
  },
  {
    id: 2,
    contratosAtivos: 'Banco do Brasil',
    dtContratada: '2025-01-22T00:00:00',
    txCaptacao: 2.0,
    modelCaptacao: 'FGI',
    vlrContratado: 10.0,
    saldo_A_Pagar: -9.8,
    saldo_Devedor: 10.0,
    porcentGarantia: 2.0,
    dtUltimaParcela: '0001-01-01T00:00:00',
    total: -19.8,
    periodoCarreira: '',
    detalhes: [
      {
        id: 1,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Parcial',
      },
      {
        id: 2,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Pendente',
      },
    ],
  },
  {
    id: 3,
    contratosAtivos: 'Safra',
    dtContratada: '2025-01-22T00:00:00',
    txCaptacao: 2.0,
    modelCaptacao: 'FGI',
    vlrContratado: 10.0,
    saldo_A_Pagar: -9.8,
    saldo_Devedor: 10.0,
    porcentGarantia: 2.0,
    dtUltimaParcela: '0001-01-01T00:00:00',
    total: -19.8,
    periodoCarreira: '10',
    detalhes: [
      {
        id: 1,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Parcial',
      },
      {
        id: 2,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Pendente',
      },
    ],
  },
  {
    id: 4,
    contratosAtivos: 'Itau',
    dtContratada: '2025-01-22T00:00:00',
    txCaptacao: 2.0,
    modelCaptacao: 'FGI',
    vlrContratado: 10.0,
    saldo_A_Pagar: -9.8,
    saldo_Devedor: 10.0,
    porcentGarantia: 2.0,
    dtUltimaParcela: '0001-01-01T00:00:00',
    total: -19.8,
    periodoCarreira: '20',
    detalhes: [
      {
        id: 1,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Parcial',
      },
      {
        id: 2,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Pendente',
      },
    ],
  },
  {
    id: 5,
    contratosAtivos: 'Bradesco',
    dtContratada: '2025-01-22T00:00:00',
    txCaptacao: 2.0,
    modelCaptacao: 'FGI',
    vlrContratado: 10.0,
    saldo_A_Pagar: -9.8,
    saldo_Devedor: 10.0,
    porcentGarantia: 2.0,
    dtUltimaParcela: '0001-01-01T00:00:00',
    total: -19.8,
    periodoCarreira: '',
    detalhes: [
      {
        id: 1,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Parcial',
      },
      {
        id: 2,
        dtContratada: '2025-01-22T00:00:00',
        mesAno: '1/2025',
        dtVencimento: '2025-01-22T00:00:00',
        nmrParcela: 1,
        dias: 0,
        vlrParcela: -9.8,
        vlrAmortizacao: -10.0,
        vlrJuros: 0.2,
        vlrSaldoApagar: 0.0,
        vlrSaldoDevedor: 10.0,
        vlrGarantia: 0.2,
        di: 12,
        quantidade: 1,
        pgtoEfetuado: 0,
        status: 'Pendente',
      },
    ],
  },
];
